/* eslint-disable no-console */
import * as d3 from 'd3';
import { addTraficTooltipListener } from './tooltip-traffic';

/**
 * Draws the traffic layer with d3.
 *
 * @param {Object} geojson - The GeoJSON data representing the property.
 * @param {Object} element - The DOM element containing the SVG and projection.
 */
export function drawTraffic( geojson, element ) {
	//console.log( 'drawTraffic called' );

	// Extract the projection and create a geo path generator
	const projection = element.projection;
	const geoGenerator = d3.geoPath().projection( projection );

	// Create a group for the traffic paths and bind the GeoJSON features data
	d3
		.select( element )
		.select( 'svg' )
		.select( 'g.wr-traffic' )
		.selectAll( 'path' )
		.data( geojson.features )
		.enter()
		.append( 'path' )
		.attr( 'd', ( d ) => {
			if ( d.geometry.type === 'LineString' || d.geometry.type === 'MultiLineString' ) {
				return geoGenerator( d );
			}
			return null;
		} )
		.attr( 'fill', 'none' ) // Ensure paths are not filled
		.attr( 'class', ( d ) => `${ d.properties.type } traffic-${ d.properties.id }` ); // Add both 'type' and 'id' as classes

	const tooltipAttr = d3.select( element ).attr( 'data-traffic-tooltip' );

	if ( tooltipAttr && tooltipAttr === 'on' ) {
		addTraficTooltipListener( element );
	}
}
