/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import * as d3 from 'd3';

export function addTraficTooltipListener( element ) {
	const mapContainer = element;
	const tooltip = d3.select( mapContainer ).select( '.wrg-tooltip' ).node();
	const trafficPaths = d3.select( mapContainer ).selectAll( 'g.wr-traffic path' );

	const clones = trafficPaths
		.clone( true ) // Klont die Pfadelemente
		.style( 'stroke-width', 24 ) // Breiter Interaktionsbereich
		.style( 'stroke', 'transparent' ) // Unsichtbar
		.style( 'pointer-events', 'stroke' ) // Interaktionen nur im Stroke-Bereich
		.classed( 'invisible-copy', true )

		.on( 'mouseover', function( event, d ) {
			// console.log( 'mouse on traffic' );
			// console.log( 'mapContainer', mapContainer );

			// Show the tooltip and set its text to the classes of the path element
			d3.select( tooltip )
				.style( 'opacity', 1 ) // Make the tooltip visible
				.attr( 'class', 'wrg-tooltip' )
				.html( createText( d3.select( this ), trafficPaths ) );

			highlightOriginal( this, trafficPaths );
		} )

		.on( 'mousemove', function( event, d ) {
			// Get the current map container
			const containerRect = mapContainer.getBoundingClientRect();
			const tooltipRect = tooltip.getBoundingClientRect();

			// Calculate mouse position relative to the container
			const mouseX = event.clientX - containerRect.left;
			const mouseY = event.clientY - containerRect.top;

			// Tooltip positioning with a small offset
			let left = mouseX + 10; // Offset for horizontal placement
			let top = mouseY + 10; // Offset for vertical placement

			// Ensure the tooltip doesn't go outside the container bounds
			if ( left + tooltipRect.width > containerRect.width ) {
				left = containerRect.width - tooltipRect.width - 10;
			}
			if ( top + tooltipRect.height > containerRect.height ) {
				top = containerRect.height - tooltipRect.height - 10;
			}

			d3.select( tooltip )
				.style( 'left', left + 'px' )
				.style( 'top', top + 'px' );
		} )

		.on( 'mouseout', function( event, d ) {
			// Hide the tooltip by setting opacity to 0
			d3.select( tooltip )
				.style( 'opacity', 0 ); // Make the tooltip invisible

			// Remove the highlight class from the current path
			unhighlightOriginal( this, trafficPaths );
		} )

		.lower();
}

function extractTrafficId( pathEl ) {
	// console.log( 'extractTrafficNumber called' );
	// console.log( 'pathEl', pathEl );
	const classList = pathEl.node().getAttribute( 'class' ).split( ' ' );
	// console.log( 'classList', classList );
	const trafficIdClass = classList.find( ( cls ) => /^traffic-\d+$/.test( cls ) );
	// console.log( 'trafficIdClass', trafficIdClass );
	// Extract the number from the traffic class
	const trafficId = trafficIdClass.replace( 'traffic-', '' );
	// console.log( 'trafficId', trafficId );
	return parseInt( trafficId );
}

function findOriginal( trafficId, trafficPaths ) {
	// console.log( 'findOriginal called' );
	// console.log( '1. parameter trafficId: ', trafficId );
	// console.log( '2. parameter trafficPaths: ', trafficPaths );

	// Search within the `trafficPaths` selection
	const original = trafficPaths.filter( function() {
		// Check if this element's class list contains the correct traffic ID class
		return d3.select( this ).classed( `traffic-${ trafficId }` );
	} );

	// console.log( 'Number of originals', original.size() );
	return original;
}

function highlightOriginal( pathEl, trafficPaths ) {
	// Remove existing highlights
	trafficPaths.selectAll( '.highlight' )
		.classed( 'highlight', false );

	// Extract the traffic ID from the current path element
	const trafficId = extractTrafficId( d3.select( pathEl ) );

	// Find the original path using the traffic ID
	const original = findOriginal( trafficId, trafficPaths );

	// Add the highlight class to the original path
	if ( original.size() > 0 ) {
		original.classed( 'highlight', true );
	} else {
		console.error( 'Original path not found for trafficId:', trafficId );
	}
}

function unhighlightOriginal( pathEl, trafficPaths ) {
	// Extract the traffic ID from the current path element
	const trafficId = extractTrafficId( d3.select( pathEl ) );
	// Find the original path using the traffic ID
	const original = findOriginal( trafficId, trafficPaths );

	// Add the highlight class to the original path
	if ( original.size() > 0 ) {
		original.classed( 'highlight', false );
	} else {
		console.error( 'Original path not found for trafficId:', trafficId );
	}
}

function extractNoneIdClasses( pathEl ) {
	// console.log( pathEl );
	const classList = pathEl.attr( 'class' ).split( ' ' );
	// console.log( 'classList', classList );
	// Get the other classes (excluding traffic-n)
	const otherClasses = classList.filter( ( cls ) => ! cls.startsWith( 'traffic-' ) ).join( ' ' );
	return otherClasses;
}

function createText( pathEl, trafficPaths ) {
	// console.log( 'createText called' );
	// console.log( 'parameter pathEl', pathEl );
	// console.log( 'createText called' );

	const trafficId = extractTrafficId( pathEl );
	// console.log( 'trafficId', trafficId );

	const original = findOriginal( trafficId, trafficPaths );
	// console.log( 'original.size()', original.size() );

	const otherClasses = extractNoneIdClasses( original );
	// console.log( 'otherClasses', otherClasses );

	// Create the HTML table string
	const tableHTML = `
		<table>
			<tr>
				<td style="text-align: left;">ID:</td>
				<td style="text-align: left;">${ trafficId }</td>
			</tr>
			<tr>
				<td style="text-align: left;">Typ:</td>
				<td style="text-align: left;">${ otherClasses }</td>
			</tr>
		</table>
	`;

	return tableHTML;
}
